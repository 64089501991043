<template>
  <div :class="'c100 p' + percent + ' orange small'">
      <span><img :src="'./assets/logos/' + image" style="width: 70%;" /></span>
      <div class="slice">
          <div class="bar"></div>
          <div class="fill"></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'SkillBadge',
  props: {
    image: String,
    percent: Number
  }
}
</script>

<style scoped>

.c100, .c100::after {
  background-color: inherit;
}

.c100.orange .bar, .c100.orange .fill {
	border-color: #ef9f00 !important;
}

</style>
