<template>

  <section class="body mt-3 mb-3">
    <div class="body-row row justify-content-center align-items-start">
      <div class="col-md-4 col-12">
        <h4>GitHub Repositories</h4>
        <ul v-if="httpCode === 200" class="repos-list">
          <li v-for="(r, idx) in reposData" :key="idx" class="repos-list-item">
            <span><a :href="r.html_url" target="_blank">{{ r.full_name }}</a></span> -
            <small>
              <span><fa-icon icon="fa-solid fa-eye" />{{ (r.private) ? " Private " : " Public " }}</span>
              <span v-if="r.fork"> <fa-icon icon="fa-solid fa-code-fork" /> Forked </span>
              <span v-if="r.homepage"> <a :href="r.homepage" target="_blank"><fa-icon icon="fa-solid fa-arrow-up-right-from-square" /> Home</a> </span>
            </small>
            <p>{{ r.description }}</p>
          </li>
        </ul>

      </div>
      <div class="col-md-3 col-12">
        <div class="card w-100 p-3 pub-key">
          <div class="card-Body">
            <h5 class="card-title">Public Key | <fa-icon icon="fa-regular fa-copy" @click="copyToClipboard()" /><small :class="{ copied: copiedStatus }"> Copied!</small></h5>
            <p class="card-text"><small>{{ pubkey }}</small></p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12">
      </div>
    </div>

    </section>

</template>


<script>
export default {
  name: 'BodySection',
  props: {
    config: Object
  },
  data() {
    return {
      reposData: {},
      pubkey: '',
      httpCode: 0,
      copiedStatus: false,
      pphWidget: ''
    }
  },
  methods: {
    getRepos(){
      this.axios('https://api.github.com/users/'+ this.config.githubUsername +'/repos')
        .then( (response) => {
          this.reposData = response.data
          this.httpCode = response.status
        })
      return this.httpCode
    },
    getPubKey(){
      this.axios('https://api.github.com/users/'+ this.config.githubUsername +'/keys')
        .then( (response) => {
          for( let key in response.data){
            this.pubkey = response.data[key].key + '\n'
          }
        })
    },
    copyToClipboard(){
      const fallbackCopyTextToClipboard = (text) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      };

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(this.pubkey);
        return;
      }
      navigator.clipboard.writeText(this.pubkey).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });

      this.copiedStatus = true;
      setTimeout(() => {this.copiedStatus = false;}, 100);
    }
  },
  mounted() {
    this.getRepos();
    this.getPubKey();
  }
}

</script>

<style scoped>

.repos-list .repos-list-item small span::after {
	content: ' | ';
}

.repos-list .repos-list-item small span:last-child::after {
	content: none;
}

.pub-key {
  max-height: 60vh;
  overflow-y: scroll;
}

.pub-key h5 small {
	opacity: 0;
	transition: opacity 1s, duration 1s;
  font-size: .75rem;
}

.pub-key h5 small.copied {
	opacity: 1;
	transition: opacity 0s, duration 0s;
}

.pub-key .card-text {
	line-break: anywhere;
	font-family: monospace;
}

.cert-level10-medium[data-v-c989913a] {
	background-position: -800px -169px;
	width: 85px;
	height: 85px;
	background-image: url('https://www.peopleperhour.com/img/badges.png?b700dff4');
	background-size: 1400%;
	border: none;
}
</style>
