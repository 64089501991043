import { createApp } from 'vue'
import SiteLayout from './Layout.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/circle.css'
import config from './assets/data/config.json'

import { library } from '@fortawesome/fontawesome-svg-core'

import { faTwitter, faGithub, faFacebook,
          faTelegram, faLinkedin, faSkype,
          faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { faAt, faPhone, faCodeFork, faArrowUpRightFromSquare, faEye } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add([faTwitter, faGithub, faFacebook,
              faTelegram, faLinkedin, faAt,
              faPhone, faSkype, faWhatsapp,
              faCodeFork, faArrowUpRightFromSquare, faEye, faCopy])

createApp(SiteLayout, {
  config: config
}).component('fa-icon', FontAwesomeIcon)
  .use(VueAxios, axios)
  .mount('#site-layout');

//app.provide( 'axios', app.config.globalProperties.axios)
