<template>
    <!-- Site Header section -->
    <HeadingSection :config="config"/>
    <BodySection :config="config" />
    <FooterSection :config="config" />
</template>

<script>
import HeadingSection from "./components/HeadingSection.vue"
import BodySection from "./components/BodySection.vue"
import FooterSection from "./components/FooterSection.vue"

export default {
  name: 'SiteLayout',
  props: {
    config: Object
  },
  components: {
    HeadingSection,
    BodySection,
    FooterSection
  }
}
</script>

<style>
#site-layout {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 1rem;
}
</style>
