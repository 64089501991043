<template>
  <div class="row justify-md-content-end justify-content-center align-items-center">
    <a v-for="(socialLink, key) in config.socialLinks" :key="key"
        class="col-1" target="_blank"
        :href="socialLink.link">
          <fa-icon
              :icon="'fa-brands fa-' + socialLink.brand"
              :style="{width: size+'rem', height: size+'rem'}" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialIcons',
  props: {
    socialLinks: Array,
    size: Number,
    config: Object
  }
}
</script>

<style scoped>

.svg-inline--fa {
  color: black;
}

@media(prefers-color-scheme: dark) {
  .svg-inline--fa {
    color: white;
  }
}

a {
  flex: 0 1 content;
}

.row {
  --bs-gutter-x: 10px;
}
</style>
