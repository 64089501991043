<template>
  <section class="header mb-3">
  <div class="heading-row row justify-content-around align-items-center">
    <div class="heading-title col-md-6 col-12">
      <h5>{{ config.title }}</h5>
      <h1>{{ config.fullName }}</h1>
    </div>
    <div class="col-md-2 col-12 align-self-end">
      <SocialIcons :size="2" :config="config" />
    </div>
  </div>
</section>
</template>

<script>
import SocialIcons from "./SocialIcons.vue"

export default {
  name: 'HeadingSection',
  props: {
    config: Object
  },
  components: {
    SocialIcons
  }
}
</script>

<style scoped>
@media (max-width: 767.98px) {
  .heading-title {
    text-align: center;
  }
}

@media (min-width: 767.98px) {
  .heading-row {
    min-height: 15rem;
  }
}
h5 {
  color: #ef9f00;
}

</style>
